/**
 * @Page ROM84
 * @Description [ROM84]납입지시 - 시작차 입고현황 - 부품식별표
 **/
import AlertDialog from "@/components/material-UI/AlertDialog";
import CustomAlert from "@/components/molecules/CustomAlert";
import Ribbon from "@/components/organisms/Ribbon";
import ContentXearch from "@/components/organisms/ContentXearch";
import Content from "@/components/organisms/Content";
import Grid from "@/components/grid/agGrid";
import ReactToPrint from "react-to-print";
import WrapStartLabelPrint from "@/components/molecules/WrapStartLabelPrint";
import React, {useCallback, useEffect, useRef, useState} from "react";
import * as Common from "@/utils/Common";
import CODES from "@/config/commonCode.json";
import CommonUtil from "@/utils/CommonUtil";


const SERVER_URL = process.env.REACT_APP_BACKEND_SERVER; 
const Utils = new CommonUtil();

// Print Style CSS Setting

const pageStyle = `
@media all {
    .pagebreak {
    display: none;
    }
    .gulim12{
    font-family:"굴림", Gulim;
    font-size:12pt;
    }    
    .gulim10{
    font-family:"굴림", Gulim;
    font-size:10pt;
    }
}

@media print {
    html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    }
}

@media print {
    .pagebreak {
    margin-top:1rem;
    display: block;
    page-break-after: always;
    }
}

@page {
    size: A4 landscape !important;    
    margin:7mm 10mm;
}
body, div, table, td{
    font-family:굴림체,Gulim ;
}

`;




function ROM84() {
    const token = Common.getToken();
    const userId = Common.getToken("id");
    // const userName = Common.getToken("name");
    const tVnt1 = Common.getToken("vand");
    // const tComp = Common.getToken("comp");
    const bigi = Common.getToken("bigi");
    const grdRom84Main = useRef();   //Grid Main
    const printRef = useRef();
    const printBtnRef = useRef();

    const ckVnt1 = Common.getCookie("ck_vnt1"+userId)===null||Common.getCookie("ck_vnt1"+userId)===""||Common.getCookie("ck_vnt1"+userId)==="undefined"?tVnt1:Common.getCookie("ck_vnt1"+userId); 

    //Data
    const [idata, setData] = useState({
        //조회조건
        kGubn: `(+)`,
        kGubn2: 'A',
        kOdno: "",
        kDlno: "",
        // kVnt1 : tVnt1,      //T1업체        
        kVnt1 : ckVnt1,        //T1업체     
        kComp: 'EHKR',       //회사구분
        kPlnt: "",          //공장
        kGate: "",          //하치장
        kPtno : "",         //품번
        kNpdt: Common.getToday(),   //시작일
        kGubn2 : "N",         //식별표인쇄
        kUsyn: "*",
        kInfo_vand: "",
        kProj:"",
        kNidtGubn:"B",
        kNidt:Common.getToday(),
        kStart : "1",        //START 출력 시작 위치

        //코드 데이터
        vnt1List : [],  //T1업체
        plntList : [],  //공장
        projList : [],  //프로젝트
        canoList : [],  //차량번호
        compList : [],  //회사
    });


    const [message, setMessage] = React.useState( {
        openAlert : false,      
        severity : "success",   
        msg : ""                
    });
    const { openAlert, severity, msg } = message;

    //Dialog
    const [dlgMessage, setDlgMessage] = React.useState( {
        openDlg : false,    
        dlgTrans : "",      // Dialog Transaction(I,U,D)
        dlgMsg : ""         
    });
    const { openDlg, dlgTrans, dlgMsg } = dlgMessage;

    //Grid Search, Insert, Update, Delete Api
    let [searchApi, setSearchApi] = useState(false); 
    let [printdata, setPrintData] = useState([]);   //Print

    const [panded, setPanded] = React.useState(true);
    const [style, setStyle] = useState({ height :"calc(100vh - 60px - 50px - 146px  - 50px)"});

    const onClearApi = useCallback(async (e) => {
        setSearchApi(false);
    }, []);



    /**
     * 공통코드 조회 (조회조건)
     */
    const onSearchCommon = (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        commonData[name] = data.data
                        setData({...commonData});
                        if (name === "plntList") {
                            commonData["kPlnt"] = idata.plntList[0].comCode;
                        }
                    }
                })
        }
        fetchData(para, name);
    }

    const onSearchCommonAsync = async (para, name) => {
        let commonData = idata;

        const fetchData = async (para, name) => {
            let param = encodeURI(para);
            const query = SERVER_URL + "/comcd/search?query=" + param; 
            await fetch(query, { headers: { Authorization: token } })
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.success)
                    {
                        commonData[name] = data.data
                        setData({...commonData});
                    }
                })
        }
        await fetchData(para, name);
    }



    /**
     *MOUNT
     */
    useEffect( () => {
        //공통코드 조회 (T1업체)
        onSearchCommon(`{"pgmid":"VNT1"}`, "vnt1List");
        
        //공통코드 조회 (공장)
        onSearchCommon(`{"pgmid":"PLNT","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}", "kGubn":"VMI"}`, "plntList");
        //공통코드 조회 (차량번호)
        onSearchCommon(`{"pgmid":"CARN"}`, "canoList");
        //공통코드 조회 (회사)
        onSearchCommon(`{"pgmid":"COMP"}`, "compList");
        //공통코드 조회 (하치장)
        onSearchCommon(`{"pgmid":"KGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}","kFidt_f":"${idata.kNidt}","kFidt_t":"${idata.kNidt}"}`, "gateList");
        //공통코드 조회 (프로젝트)
        onSearchCommon(`{"pgmid":"PROJ","kVand":"${idata.kVnt1}"}`, "projList");
    }, []);



    /**
     * Control 변경 Event
     * @param {*} e
     */
    const onChange = async (e) => {
        const {name, value} = e.target;

        let newSearchs = idata;
        newSearchs[name] = value
        setData({ ...newSearchs });

        // 공장 변경 시
        if (name === "kPlnt") {
            //공통코드 조회 (하치장)
            await onSearchCommonAsync(`{"pgmid":"KGATE","kVnt1":"${idata.kVnt1}","kComp":"${idata.kComp}","kPlnt":"${idata.kPlnt}"}`, "gateList").then(
                () => {
                    if (idata.gateList.length > 0) {
                        setData({...idata, kGate: idata.gateList[0].comCode});
                    }
                }
            );
        }

    }

    const grdRom84Main_onRowClicked = async (e) => {
    }

   

    /**
     * 검색조건
     */
    const optionsX = {
        0: [
            //T1업체
            {
                formatter: "select", name: "kVnt1", labelName: "T1업체", type: "text", defaultValue: idata.kVnt1,  
                codeMode: true,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.vnt1List, 
                align: "left", 
            },
             //납기일자
             {
                "formatter": "date", "name": "kNidt", "name2": "kNidtGubn", "labelName": "납기일자"
                , "defaultValue": idata.kNidt
                , "defaultValue2": idata.kNidtGubn  
                , "align": "right" // (우측 정렬)
                , "labelType": "none" 
//                ,"items": [{ "comCode": "Y", "text": "인쇄" }, { "comCode": "N", "text": "미인쇄" }]
            },
             // 납품일자
             {
                formatter: "input", name: "kNpdt", type: "date", labelName: "납품일자"
                , defaultValue: idata.kNpdt
                , align: "left" // (우측 정렬)
                , labelType: "none" 
            },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ],
        1: [
             //회사구분
            {
                "formatter": "select", "name": "kComp", "labelName": "회사구분", "type": "text", "defaultValue": idata.kComp,  
                "codeMode": true,
                "selectAll": "",
                //"codes": CODES["comp"],
                "comms": null,
                "align": "left", 
                "items": [{ "comCode": "EHKR", "text": "남양시작차" }]
            }, 
            //식별표인쇄
            {
                "formatter": "select", "name": "kGubn2", "labelName": "식별표인쇄", "type": "text", "defaultValue": idata.kGubn2,  
                "codeMode": false,
                "selectAll": "",
                "codes": null,
                //"comms": idata.gubnList,
                "items": [{ "comCode": "*", "text": "전체" },{ "comCode": "Y", "text": "인쇄" }, { "comCode": "N", "text": "미인쇄" }]
            }, 
            //프로젝트
            {
                "formatter": "select", "name": "kProj", "labelName": "프로젝트", "type": "text", "defaultValue": idata.kProj,  
                "codeMode": true,
                "selectAll": true, // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                "codes": null,
                "comms": idata.projList,
                "align": "left", 
                }, 
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ],
        2:[
            // 공장
            {
                formatter: "select", name: "kPlnt", labelName: "공장", type: "text", "defaultValue": idata.kPlnt,  
                codeMode: false,
                selectAll: "", // selectAll :: 전체 option 표출 여부 :: { "comCode" : "" , "text" : "전체" }
                codes: null,
                comms: idata.plntList,
                align: "left", 
            },
            // 품번
            {
                formatter: "input", name: "kPtno", type: "text", labelName: "품번"
                , defaultValue: idata.kPtno
                , align: "left" // (우측 정렬)
                , labelType: "none" 
            },
           //Start
           {
            "formatter": "select", "name": "kStart", "labelName": "START", "type": "text", "defaultValue": idata.kStart,  
            "codeMode": false,
            "selectAll": "",
            "codes": CODES["start"],
            "comms": null,
            "align": "right", 
            },
            {"formatter": "blank", "align": "right" },
            {"formatter": "blank", "align": "right" }
        ]
    }



    /**
     * 조회
     * @param {*} e
     * @returns
     */
    const onSearch = async (e) => {
        
        // if(idata.kPlnt === ''){
        //     idata.kPlnt=idata.plntList[0].comCode;
        // }


        //공장선택 Check
        if (idata.kPlnt === "")
        {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "공장을 선택하십시요 !"});
            document.getElementById("kPlnt").focus();                         
            return ;
        }
        

        // Cookie 값 셋팅
        Common.setCookie("ck_vnt1"+userId,idata.kVnt1,1);

        let para = `{
                        "kVnt1": "${idata.kVnt1}", "kComp": "${idata.kComp}",
                        "kProj": "${idata.kProj}",
                        "kPlnt": "${idata.kPlnt}", "kPtno": "${idata.kPtno}",
                        "kNpdt": "${idata.kNpdt.replaceAll('-','')}",
                        "kNidt": "${idata.kNidt.replaceAll('-','')}",
                        "kOdno": "${idata.kOdno}","kNidtGubn":"${idata.kNidtGubn}",
                        "kGubn2":"${idata.kGubn2}"
                     }`;

        let param = encodeURI(para);

        const query = SERVER_URL + "/rom84/search?query=" + param;
        setSearchApi(query); 
    }

    const grdRom84Main_onCellValueChanged = async (e) => {
        
         let result;

        let data = e.data;
        
        if (data.npqt === "0" || data.npqt === "") {
            e.node.setSelected(false);
        }

        //납품수량과 용기당수 계산을 하여 용기수 Setting
        if (e.column.colId === "qypc") {

            if (data.npqt !== "" || data.npqt !==null ) {
                result = Math.ceil(parseInt(data.npqt) / parseInt(data.qypc));
            }
            else {
                result = 0;
            }
            e.node.setDataValue('caseqty', result);
        }

        //발주량 대비 납품수량 Check
        if (e.column.colId === "npqt") {

            let npqt = !isNaN(parseInt(data.npqt, 10)) ? parseInt(data.npqt, 10) : 0;
            let odqt = !isNaN(parseInt(data.odqt, 10)) ? parseInt(data.odqt, 10) : 0;

            if (npqt  > odqt) {
                setMessage({ openAlert : true, 
                             severity : "info",
                             msg : "오더 잔량보다 더 많이 납품할 수 없습니다."});
                e.node.setDataValue('npqt', 0);    
                e.node.setDataValue('caseqty', 0);                         
                return 
            }
            else {

                if (data.npqt !== "" || data.npqt !==null ) {
                    result = Math.ceil(parseInt(data.npqt) / parseInt(data.qypc));
                }
                else {
                    result = 0;
                }
                e.node.setDataValue('caseqty', result);
            }
        }

    }

    const grdRom84Main_onCellClicked = async (e) => {
    }



    /**
     * 신규 Row추가
     * @param {*} e
     */
    const onAddRow = async (e) => {
    }



    /**
     * 입력 (발행)
     * @param {*} e
     */
    const onInsert = async (e) => {

    }



    /**
     * 수정
     * @param {*} e
     * @returns
     */
    const onUpdate = async (e) => {
    }

    /**
     * 삭제
     * @param {*} e
     */
    const onDelete = async (e) => {
    }

    /**
     * 엑셀
     * @param {*} e
     */
    const onExcel = async (e) => {

    }


    //Transaction AlertDialogResult
    const AlertDialogResult = async (e) => {

        //AlertDialog 초기화
        // setDlgMessage({ openDlg : false,  dlgTrans : "", dlgMsg : ""});
        setDlgMessage({ ...dlgMessage, openDlg : false});

        //DialogResult
        if (e.result == "N") return;

        //Transaction 구분
        switch (e.Trans)
        {
            //등록
            case "I" :
                break;
            //수정
            case "U" :
                break;
            //출력
            case "P" :
                let selectedData = grdRom84Main.current.getSelectedData();

                const fetchData = async () => {
                    let selectedDataItems = [];
                    let selectDataList = {};
                    let selectPtnoList = [];
                    let selectOdnoList = [];


                    selectedData.forEach((data) => {
                        selectPtnoList.push(data.ptno);
                        selectOdnoList.push(data.odno);
                        selectDataList = {vnt1 :idata.kVnt1, comp : idata.kComp, plnt : idata.kPlnt,  odno : data.odno,
                                          ptno:data.ptno, stck:data.stck, qypc:data.qypc, npqt:data.npqt, caseqty : data.caseqty} 
                        selectedDataItems.push(selectDataList)
                    });

                    
                    const query = SERVER_URL + "/rom84/print"; 
                    let param = JSON.stringify({
                        parmData : {vnt1 : idata.kVnt1,
                                    comp : idata.kComp,
                                    plnt : idata.kPlnt,
                                    ptnolist : selectPtnoList,
                                    odnolist : selectOdnoList,
                                    selectedData:selectedDataItems
                                    },
                    });

                    
                    await fetch(query, { method: "POST", headers: { Authorization: token, "Content-Type": "application/json" }, body: param })
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (data.success) {

                                setPrintData(data.data);
        
                                // 성공 메시지 처리 필요
                                setMessage({ openAlert : true, 
                                             severity : "success",
                                             msg : "인쇄 조회 성공 하였습니다."});

                                             
                                //print
                                printBtnRef.current.dispatchEvent(new Event("click", { bubbles: true }));
                                
                                setTimeout(() => onSearch(), 300);
                            }
        
                            if (data.error) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : "조회 실패"});
                            }
                        })
                        .catch((error) => {
                            // 에러 응답 처리
                            if (error) {
                                setMessage({ openAlert : true, 
                                             severity : "error",
                                             msg : "서버 상태를 확인해 주세요. 관리자에게 문의해주세요."});
                            }
                        });
                };
        
                fetchData();
                
                break;

            }
        }
    
/**
     * 인쇄 
     * @param {*} e 
     * @returns 
     */
 const onPrint = async (e) => {
        
    let inputFlag = true;


    //select nodes
    let selectedData = grdRom84Main.current.getSelectedNodes();

    // 선택 값 체크
    if (selectedData.length === 0) {
        //Alert 창 OPEN
        setMessage({ openAlert : true, 
                    severity : "info",
                    msg : "선택한 데이터가 없습니다."});
        return;
    }

    //필수입력항목 Check
    selectedData.forEach((node) => {

        if (node.data.npqt === "" || node.data.npqt === "0") {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "납품수량을 입력하여 주십시오."});
            inputFlag = false;    
            grdRom84Main.current.SetFocus(node.rowIndex, "npqt");                             
            return ;
        }
        else if (node.data.stck === "" || node.data.stck === "0") {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "부착면수를 입력하세요."});
            inputFlag = false;                                 
            grdRom84Main.current.SetFocus(node.rowIndex, "stck");     
            return;
        }
        else if (node.data.qypc === "" || node.data.qypc === "0") {
            setMessage({ openAlert : true, 
                         severity : "info",
                         msg : "용기당수를 입력하세요."});
            inputFlag = false;                                 
            grdRom84Main.current.SetFocus(node.rowIndex, "qypc");     
            return;
        }   
    });

    if (!inputFlag) return;

    //출력 확인 메세지 
    setDlgMessage({openDlg : true,  
                   dlgTrans : "P", 
                   dlgMsg : "시작차 부품식별표는 A타입 인쇄입니다. 부품식별표 용지를 확인하십시요. 인쇄 하시겠습니까?" });

   
    }
    
    const editCellClicked = (params) => {
        // default Cell Click 이벤트 

        let gridApi = params.api;

        let rowIdx = params.rowIndex;

        let data = params.data;


        if ((data.npqt === 0 || data.npqt === "")) {

            gridApi.stopEditing(true); // Grid EditMode stop

            //var rowNode = gridApi.getRowNode(rowIdx);
            var rowNode = gridApi.getDisplayedRowAtIndex(rowIdx); //정렬, 필터링... 하면 변경된 index가져옴
            rowNode.setDataValue("npqt", data.odqt); // 발주수량 값 받아와서 납품수량npqt 셋팅 


            // params.api.startEditingCell({
            gridApi.startEditingCell({
                // rowIndex: params.rowIndex,
                rowIndex: rowIdx,
                colKey: 'npqt' // 납품수량npqt edit Mode
            }); // Grid EditMode start


        }

    };

    /**
     *Grid Column 정의
     */
        //Main Grid Column
    const mainColumnDefs = [
            { headerName: "납기일자", field: "nidt", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, valueGetter: Utils.dateGetter, hide: false },
            { headerName: "품번", field: "ptno", sortable: true, filter: true, flex: 1, minWidth: 130, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true,valueGetter: Utils.partnoGetter, hide: false },
            { headerName: "발주번호", field: "odno", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "발주수량", field: "odqt", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "납품수량", field: "npqt", sortable: true, onCellClicked: editCellClicked,
                filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "용기당수", field: "qypc", sortable: true, filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "용기수", field: "caseqty", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "면수", field: "stck", sortable: true, filter: true, flex: 1, minWidth: 150, editable: true, headerClass: "editCell", cellClass: ["nocheckbox", "alignR"], singleClickEdit: true, hide: false },
            { headerName: "차종", field: "cdsc", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignC"], singleClickEdit: true, hide: false },
            { headerName: "사양", field: "optn", sortable: true, filter: true, flex: 1, minWidth: 150, editable: false, headerClass: "", cellClass: ["nocheckbox", "alignL"], singleClickEdit: true, hide: false },
        ];

        const onClick = async (e) => {
            setPanded(!panded);
    
            if(panded===true){
                setStyle({       
                    height :"calc(100vh - 60px - 50px - 50px)"
                  });
            }else{
                setStyle({
                    height :"calc(100vh - 60px - 50px - 146px  - 50px)"
                  });
            }
        }

    return(
        <>
            {/* Dialog FRAME */}
            <AlertDialog open={openDlg}  trans={dlgTrans} message={dlgMsg} onClose={AlertDialogResult} />

            {/* Alert FRAME */}
            <CustomAlert open={openAlert} severity={severity} duration={5000} message={msg} onClose={()=> {setMessage({...message, openAlert:false})}} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

            {/* Ribbon Frame : 프로그램 Path 및 버튼 */}
            <Ribbon
                onSearch={onSearch} onSearchName={null}     //조회
                onPrint={onPrint} onPrintName={"인쇄"}
            >
                {"납입지시 > 시작차 출하관리 > "}<b style={{ color: "#cd5c5c", fontFamily: "PretendardLight, NanumGothic, sans-serif" }}>부품식별표(시작차)</b>
            </Ribbon>


            {/* ContentXearch Frame : 검색조건 */}
            <ContentXearch
                optionsX={optionsX}
                onChange={onChange}
                onClick={onClick}/>


            {/* Content FRAME (Data / Grid Frame) START -------------- */}
            <Content style={style}> {/* Content 항목에서의 100%에서 Header:60px,  Ribbon:50px,  검색조건:146px */}
                <div className={"grid_type01"}> {/* 상단 FRAME */}
                    <div className="grid_cont_box" style={{ height: "calc(100%)" }}>
                        <Grid
                            ref={grdRom84Main}
                            gridId={"ROM84"}                    //  (필수) Grid Id 정의
                            columnDefs={mainColumnDefs}             //  (필수) 컬럼 정의
                            pagination={true}                  //  쪽수 매기기 여부
                            paginationCsv={false}                //  CSV 사용구분 - pagination과 동시에 사용 필요
                            paginationPageSize={"1000"}         //  쪽수 1Page 당 Row수
                            rowMultiSelectWithClick={true}      //  멀티 클릭 가능 여부
                            suppressRowClickSelection={true}   //  클릭으로 선택 하는것을 방지
                            rowSelection={"multiple"}           //  여러개 선택 "multiple" 가능, 한개 선택할 때는 "single"
                            enableRangeSelection={false}        //
                            enableCellTextSelection={true}      // cell selection drag mode
                            isFirst={true}                     // 첫번째 컬럼 체크박스 사용여부 - ( default 체크박스 사용안함 )
                            displayNo = {false}
                            searchApi={searchApi}               // 조회
                            onClearApi={onClearApi}             
                            onCellValueChangedEvent={grdRom84Main_onCellValueChanged}
                            onRowClickEvent={grdRom84Main_onRowClicked}
                            onCellClicked={grdRom84Main_onCellClicked}
                        />
                    </div>
                </div>
            </Content>


            <div className="printArea">
                <ReactToPrint
                onBeforeGetContent={() => { return new Promise((resolve) => { setTimeout(() => { resolve();  }, 300);});}} trigger={() => <button className="btn" ref={printBtnRef} style={{ display: "none" }} />} pageStyle={pageStyle} content={() => printRef.current} />
                <WrapStartLabelPrint ref={printRef} printData={printdata} idata={idata} />
            </div>
            
            {/* <div>
                <WrapStartLabelPrint printData={printdata} idata={idata} />
            </div> */}


        </>
    )
}
export default ROM84;